<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-shopping-basket animated bounceInDown show-info"></i>
                        <span v-if="this.trantype==1">Add Purchase</span>
                        <span v-if="this.trantype==2">New Sales</span>
                        {{ this.trantype }}
                    </h3>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6" style="padding-top: 18px">
                            <!-- <button @click="refresh()" class="btn btn-primary">
                                <i class="fa fa-refresh"></i> Refresh
                            </button> -->
                            <button v-if="edititem != null" @click="back()" class="btn btn-primary">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-cascade">
            <div class="panel-body">
                <div class="form-horizontal cascde-forms">
                    <div class="col-md-12">
                        <div class="form-horizontal cascde-forms">
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Select Party</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="form.customerid">
                                        <option selected>----Select-----</option>
                                        <option v-for="item in parties" v-bind:value="item.id" :key="item.id">
                                            {{ item.firmname }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Purchase No.<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" disabled v-model="serialno"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Invoice No.<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" v-model="form.invoiceid"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">Invoice Date<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" v-model="form.invoicedate"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Select
                                        Category</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="form.parentid">
                                        <option selected>----Select-----</option>
                                        <option v-for="item in parentcategory" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Select Product</label>
                                </div>
                                <div class="col-md-6">
                                    <select @change="fetchbrands()"
                                        class="form-control form-cascade-control input-small" v-model="category_id">
                                        <option selected>----Select-----</option>
                                        <option v-for="item in subcategory" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class=" col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Company</label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="brandid" @change="fetchproducts()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in brands" :key="filter.id" :value="filter.id">
                                            {{ filter.value }}</option>

                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="firstname" class="col-form-label control-label">Model Name<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="form.productid" @change="fetchgst()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in products" :key="filter.id" :value="filter.id">
                                            {{ filter.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">Price Without Tax<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="number" v-model="cpwithouttax" @input="fixcpwithtax()"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">CGST<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="number" v-model="cgstprice" 
                                        class="form-control form-cascade-control input-small" disabled>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">SGST<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="number" v-model="sgstprice" 
                                        class="form-control form-cascade-control input-small" disabled>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">GST<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6 ">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>CGST </label>
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="number" v-model="cgst"
                                                        class="form-control form-cascade-control input-small" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>SGST </label>
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="number" v-model="sgst"
                                                        class="form-control form-cascade-control input-small" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">Price With Tax<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="number" v-model="costprice"
                                        class="form-control form-cascade-control input-small" disabled>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Quantity<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" v-model="quantity" @input="getAllBarcode()"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                                <div class="col-md-2">

                                </div>
                            </div>

                            <div class="form-group row" v-if="this.barcodes.length > 0">
                                <div class="col-md-4">
                                    <div class="row">

                                        <div class="col-md-8">
                                            <label for="gender" class="col-form-label control-label">Serial No./
                                                IMEI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                        </div>
                                        <div class="col-md-4 flex-start-row">
                                            <input type="checkbox" id="randomly" @change="randomlyBarcode2()"
                                                :disabled="this.loading ? true : false" v-model="isChecked">
                                            <label for="randomly" class="ml-5">Randomly</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 row">
                                    <div class="col-md-6  " v-for="(value, index) in barcodes" :key="index">
                                        <barcode-item :value="value" :index="index"
                                            @updateedititem="barcodeupdated($event, index)"></barcode-item>
                                    </div>
                                    <!-- <div>
                                        <button @click="savebarcode()" class="btn btn-secondary" v-if="!loading"
                                            style="line-height: 14px;margin: 0px 15px;">Save</button>
                                    </div> -->
                                </div>


                            </div>
                            <div class="form-">
                                <button type="button" class="btn bg-primary text-white btn-md">
                                    <span v-if="form.productid != 0" @click="addproduct()">Add In Cart</span>
                                    <span v-if="form.productid == 0">Select Product</span>
                                </button>
                                <span v-show="showmsg == true" style="color:red"> Select Product First</span>
                            </div>
                            <!-- <div class="col-md-12 text-right">
                            <button class="badge btn-success btn-xs" @click="openproducts()">Add Products</button>
                        </div> -->
                            <hr>
                            <cart></cart>
                            <div class="form-group row">
                                <div class="col-md-3">

                                </div>
                                <div class="col-md-6">
                                    <input style="width: 50%;margin-top: 10%;" type="button" value="Submit"
                                        @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import Cart from '../cart/Cart.vue'
import BarcodeItem from '../cart/BarcodeItem.vue'
import axios from 'axios'
//import CartItemTax from '../cart/CartItemTax.vue'
export default {
    mixins: [Constants],
    components: { Cart, BarcodeItem },
    data() {
        return {
            form: new window.Form({
                id: 0, customerid: 0, invoiceid: 0, purchaseno: 0, invoicedate: null, parentid: 0, parent_id: 0,
                brandid: 0, productid: 0, customername: '', mobileno: null, quantity: 0, price: 0, manufdate: null,
                netpricewithouttax: 0
            }),
            newcustomer: false,
            products: [],
            category_id: 0,
            brandid: 0,
            brands: [],
            serialno: 0,
            showmsg: false,
            barcodes: [],
            param: { id: 0, quantity: 1, barcode: '', colour: '' },
            quantity: 0,
            isChecked: false,
            loading: false,
            costprice: 0,
            cpwithouttax: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
            cgstprice: 0,
            sgstprice: 0,
            barcodecount:0
            // barcodeHide:true


        }
    },

    computed: {
        ...mapGetters([
            'cartitems', 'viewno', 'parties', 'loggedinuser', 'categories', 'attributes', 'allproducts',
            'hsnbasedtaxes', 'edititem', 'totalnetprice', 'trantype'
        ]),
        parentcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == 0)
            }
            return [];
        },
        subcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == this.form.parentid)
            }
            return [];
        },
        /*brands(){
            let attribute = this.attributes.find(block=>block.id==2)
            return attribute
        },*/
        predefinedvalues() {
            if (this.brands != null && this.brands.predefinedvalues != null) {
                return this.brands.predefinedvalues
            }
            return [];
        },

        filters() {
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct() {
            if (this.products != null) {
                return this.products.find(block => block.id == this.form.productid)
            }
            return [];
        },
        finalamount() {
            let amt = parseInt(this.form.quantity) * parseInt(this.form.price)
            return amt
        },
        // quantity: {
        //     set(value) {
        //         console.log("value", value)
        //         this.barcodes = []
        //         if (value > 0) {
        //             this.getBarcode(value, this.param)
        //         } else {
        //             console.log("new producxt")
        //         }
        //     },
        //     get(value) {
        //         console.log("cart item", this.cartitems)
        //         if (value > 0) {
        //             console.log("New value",value)
        //             return value
        //         } else {
        //             console.log("old value",value)
        //             return ''
        //         }
        //     }
        // }
    },
    mounted() {
        if(window.location.pathname=='/addpurchase'){
			this.$store.commit("updatetrantype",1)
		}
        this.initializedata();
        this.refresh();
        /* if(this.$router.currentRoute.path=='/addpurchase'){
             this.$store.commit("assignedititem",null)
             this.initializedata();
             this.refresh();
         }*/
    },
    // watch: {
    //     barcodes: function(){
    //         console.log("barcodes print in watch",this.barcodes)
    //     }
    // },

    methods: {
        refresh() {
            this.$store.dispatch("fetchproducts")
            this.$store.dispatch('fetchcategories')
            this.$store.dispatch('fetchattributes')
            this.$store.dispatch('fetchmaster')
            this.$store.dispatch('fetchparties')
            window.axios.post('api/usersequence/fetchpurchase')
                .then(response => this.processSequenceResponse(response.data))
                .catch(error => console.log(error));
        },
        processSequenceResponse(data) {
            if (this.edititem == null) {
                if (data != 0) {
                    this.serialno = parseInt(data) + 1
                } else {
                    this.serialno = 1
                }
            }
        },
        addproduct() {
            if (parseInt(this.quantity) == parseInt(this.barcodecount)) {
                if (this.quantity > 0) {
                    if (this.form.parentid != 0) {
                        this.productchange();
                    } else {
                        this.showmsg = true
                    }
                } else {
                    this.$notify({ text: 'Quantity should not be 0.', type: 'warn' })
                }
            }else {
                alert('Enter serial no. atleast 6 character')
            }
        },
        initializedata() {
            if (this.edititem != null) {
                console.log("edititem")
                // console.log(this.edititem)
                this.form.id = this.edititem.id
                this.serialno = this.edititem.usersequence
                this.form.customerid = this.edititem.customerid
                this.form.invoiceid = this.edititem.invoiceid
                this.form.purchaseno = this.edititem.purchaseno
                this.form.invoicedate = this.edititem.invoicedate
                this.form.customername = this.edititem.customername
                this.form.trantype = this.edititem.trantype
                this.form.isinvoice = this.edititem.isinvoice
                // this.form.parentid

            } else {
                this.form = new window.Form({
                    id: 0, customerid: 0, invoiceid: 0, purchaseno: 0, invoicedate: new Date().toISOString().slice(0, 10), parentid: 0, parent_id: 0,
                    brandid: 0, productid: 0, customername: '', mobileno: null, quantity: 0, price: 0, manufdate: null,
                    netpricewithouttax: 0, pricewt: 0
                })
                this.products = [],
                    this.category_id = 0,
                    this.brandid = 0,
                    this.brands = [],
                    this.barcodes = []
                this.quantity = 0
                this.$store.commit('assigntransaction', null)
                this.$store.commit('assignedititem', null)
                this.$store.commit('assigncartitems', [])
            }
        },
        setapplicabletaxes() {
            let alltaxes = this.getmastertype(this.MASTER_TAXES)
            let outsideup = false;
            /*if(this.selectedcustomer!=null && this.selectedcustomeraddressid>0){
                let selectedaddress = this.selectedcustomer.addressess.find(block=>block.id==this.selectedcustomeraddressid)
                if(typeof selectedaddress!=='undefined'){
                    if(selectedaddress.state!=this.billingstate){
                        outsideup = true
                    }
                }
            }*/

            if (outsideup) {
                let index = alltaxes.findIndex(block => block.labelid == this.CGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
                index = alltaxes.findIndex(block => block.labelid == this.SGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
            } else {

                let index = alltaxes.findIndex(block => block.labelid == this.IGST_LABELID)

                if (index > -1)
                    alltaxes.splice(index, 1)
            }

            this.$store.commit("assignapplicabletaxes", alltaxes)
        },

        fetchbrands() {

            let param = { parentcategoryid: this.category_id, attributeid: 2 }
            window.axios.post('api/attribute/values/fetchbycategory', param)
                .then(response => this.processAvailableBrands(response.data))
                .catch(error => console.log(error));
        },
        processAvailableBrands(data) {
            this.brands = data
        },
        fetchproducts() {
            let param = { parentcategoryid: this.category_id, attributeid: 2, attributevalueids: this.brandid }
            window.axios.post('api/products/fetchbyfilters', param)
                .then(response => this.processProductsResponse(response.data))
                .catch(error => console.log(error));
        },
        processProductsResponse(data) {
            let createSet = new Set();
            let uniqueArray = data.filter(obj => {
                let mappingObj = obj.name;
                if (!createSet.has(mappingObj)) {
                    createSet.add(mappingObj);
                    return true;
                }
                return false;
            });
            this.products = uniqueArray
        },
        fetchgst() {
            this.cgst = 0
            this.sgst = 0
            let product = this.products.find(block => block.id == this.form.productid)
            let tax = product?.tax
            // console.log("tax", tax)
            if (tax != null) {
                for (let i = 0; i < tax.length; i++) {
                    if (tax[i].labelid == 1) {
                        this.cgst = parseFloat(tax[i].value1)
                    } else if (tax[i].labelid == 2) {
                        this.sgst = parseFloat(tax[i].value1)
                    } else if (tax[i].labelid == 3) {
                        this.igst = parseFloat(tax[i].value1)
                    }
                }
            }
            this.gstTax = parseFloat(this.cgst) + parseFloat(this.sgst)
        },
        productchange() {
            this.setapplicabletaxes();
            this.$store.commit("assignselectedproductid", this.form.productid)
            let newitem = this.products.find(block => block.id == this.form.productid)
            let newitem1 = { ...newitem }
            newitem1.sp = this.costprice
            newitem1.quantity = this.quantity
            newitem1.barcodes = this.barcodes
            this.$store.commit('addcartitem', newitem1);
            this.$store.commit('updatetrantype', 1);
            this.parentid = 0
            this.category_id = 0
            this.brandid = 0
            this.form.productid = 0
            this.quantity = 0
            this.barcodes = []
            this.loading = false
            this.isChecked = false
            this.costprice = 0
            this.cpwithouttax = 0
            this.cgst = 0
            this.sgst = 0
            this.igst = 0
            this.sgstprice = 0
            this.cgstprice = 0
            this.barcodecount = 0

        },

        submit() {
            if (this.cartitems.length > 0) {
                let checkBarcode = this.cartitems.every((item) => {
                    return item.barcodes.every((barcode) => {
                        return barcode.barcode !== "" && barcode.barcode !== null;
                    });
                });
                
                if(checkBarcode){
                    this.submitPos()
                }else {
                    this.$notify({ text: 'Fill all serial no.', type: 'error',  });
                    return false;
                }
            }else {
                this.$notify({ text: 'Fill all required field', type: 'warn', duration: '50000',speed: '500', closeOnClick: false})
            }
        },
        submitPos(){
            let param = {
                    items: this.cartitems, amountwithtax: this.totalnetprice,
                    customerid: this.form.customerid, purchaseno: this.form.purchaseno,
                    storeid: 10, invoicedate: this.form.invoicedate == null ? new Date().toISOString().slice(0, 10) : this.form.invoicedate,
                    transactionid: this.form.id, trantype: 1, invoiceid: this.form.invoiceid,
                    storeid2: 0, address_id: 0, transactiondate: new Date().toISOString().slice(0, 10),
                    isinvoice: 1, hsnbasedtaxes: null, mfgdate: this.form.mfgdate,
                    parentid: this.form.parentid, brandid: this.brandid, oldtrantype: 1,
                    customername: this.customername, mobileno: this.mobileno, usersequence: this.serialno,fy:this.getCurrentFinancialYear(),
                }
                console.log("param ",param)
                this.$store.commit('assignloadingstatus', 1)
                window.axios.post('api/submittransaction2', param)
                    .then(response => this.processResponse(response.data))
                    .catch(error => console.log(error));
                this.$notify({
                    text: 'Submitted',
                    type: 'warn',
                    duration: '50000',
                    speed: '500',
                    closeOnClick: false
                })
        },
        processResponse() {
            this.$notify({
                text: 'Submitted',
                type: 'success',
                duration: '50000',
                speed: '500',
                closeOnClick: false
            })
            this.$store.commit('assignloadingstatus', 0)
            
            this.initializedata();
            this.refresh();
            this.$store.commit('clear');
            this.$store.commit('assignviewno', 1);
            if (this.edititem != null) {
                this.$parent.refresh()
            }
            this.$store.commit('assignedititem', null)
            // if (this.edititem != null) {
            //     window.location.reload();
            // }
            // this.$store.commit('assignedititem', null);

        },
        back() {
            this.$store.commit("assignviewno", 1)
            this.$store.commit("assignedititem", null)
            this.$store.commit('assigncartitems', [])
        },
        getAllBarcode() {
            this.getBarcode(this.quantity, this.param)

        },
        getBarcode(quantity, param) {
            // console.log(param)
            this.barcodes = []
            if (quantity > 0) {
                for (let i = 0; i < quantity; i++) {
                    this.barcodes.push(param)
                    this.barcodeCount()
                }
            } else {
                this.barcodes = []
            }
        },
        barcodeupdated(event, index) {
            // console.log("Printing the event value")
            // console.log(event)
            this.barcodes[index] = {
                id: 0,
                barcode: event?.barcode,
                colour: event?.colour,
                quantity: 1
            }
            this.barcodeCount()
        },
        barcodeCount(){
            let count = 0
            if (this.barcodes.length > 0) {
                this.barcodes.forEach((element) => {
                    if (element.barcode != "") {
                        if(element?.barcode?.length > 5){
                            count++
                        }
                        
                    }
                })
                this.barcodecount = count
            }
        },
        // savebarcode() {
        //     console.log("bar code", this.barcodes)
        //     this.isChecked = false
        //     this.loading = true
        // },
        randomlyBarcode2() {
            if (this.isChecked) {
                this.$store.commit('assignloadingstatus', 1)
                const date = new Date()
                let param = {
                    productid: this.form.productid, quantity: this.quantity,
                    month: date.getMonth() + 1, year: this.getCurrentFinancialYear(),
                }
                console.log(param)
                axios.post('api/barcode/lastsequence', param)
                    .then((response) => this.processRandomBarcodeResponse2(response.data))
                    .catch((error) => console.log(error))
            } else {
                // console.log("checked value", this.isChecked)
                for (let i = 0; i < this.quantity; i++) {
                    this.$set(this.barcodes, i, { ...this.barcodes[i], barcode: '' });
                    this.barcodeCount()
                }
            }
        },

        processRandomBarcodeResponse2(data) {
            let startsequence = data?.sequence?.endsequence
            if (startsequence == '' || startsequence == null) {
                startsequence = 1
            }
            let param = {
                productid: this.form.productid, quantity: this.quantity, sequencelength: 3,
                startsequence: startsequence, endsequence: parseInt(startsequence) + parseInt(this.quantity)
            }
            axios.post('api/barcode/generate', param)
                .then((response) => this.processRandomBarcodes(response.data))
                .catch((error) => console.log(error))
        },
        processRandomBarcodes(data) {
            // console.log(data)
            data.forEach(element => {
                for (let i = 0; i < element.barcodes.length; i++) {
                    this.$set(this.barcodes, i, { ...this.barcodes[i], barcode: element.barcodes[i].barcode });
                }
            });
            this.barcodeCount()
            this.$store.commit('assignloadingstatus', 0)

        },
        fixcpwithtax() {
            if (this.cpwithouttax > 0 && this.cpwithouttax != '') {
                // this.costprice = parseFloat(parseFloat(this.cpwithouttax)+parseFloat(this.cpwithouttax*(this.gstTax/100))).toFixed(2)
                this.cgstprice = parseFloat(this.cpwithouttax * parseFloat(this.cgst / 100)).toFixed(2)
                this.sgstprice = parseFloat(this.cpwithouttax * parseFloat(this.sgst / 100)).toFixed(2)
                this.costprice = parseFloat(this.cpwithouttax * (1 + parseFloat(this.gstTax) / 100)).toFixed(2)
            } else {
                this.costprice = parseFloat(this.cpwithouttax).toFixed(2);
                this.cgstprice = 0
                this.sgstprice = 0
            }

        }
    },
}
</script>